.signUp-container {
    width: 100vw;
    height: auto;
    background: url(./abc11.png) no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px; /* Added padding for mobile */
    margin-top: 60px;
}

.signUp-card {
    width: 100%;
    max-width: 400px; /* Ensure a max-width for larger screens */
    height: auto;
    padding: 20px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
    margin: auto;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    margin-top: 100px;
    border: 3px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 50px;
}

.signUp-card > h2 {
    color: #fff;
    font-size: 1.8em;
    text-transform: uppercase;
    padding: 10px 0;
    text-align: center;
}

.form-group {
    position: relative;
    width: 100%;
    margin: 20px 0;
    border-bottom: 3px solid #fff;
    font-size: 15px;
}

.form-group .password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #fff;
    font-size: 1.2em;
}

.form-group input {
    width: 100%;
    height: 50px;
    padding: 0 35px 0 10px;
    font-size: 1.1em;
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
}

.form-group label {
    position: absolute;
    top: 40%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 1px;
    color: #fff;
    transition: 0.5s;
}

input:focus ~ label,
input:valid ~ label {
    top: -5px;
}

.form-group button{
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    color: #fff;
    border: 1px solid white;
    padding: 0px 10px;
    border-radius: 10px;
}


.form-group button:hover{
    color: black;
    background-color: white;
}

.form-group .fa-envelope,
.form-group .fa-phone,
.form-group .fa-code,
.form-group .fa-clipboard,
.form-group .fa-code-branch,
.form-group .fa-user,

.form-group .fa-lock {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1.2em;
}

p {
    text-align: center;
    color: #fff;
    margin: 10px 0;
}

p > a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
}

p > a:hover {
    text-decoration: underline;
    font-style: italic;
}

#btn {
    width: 100%;
    max-width: 250px;
    border-radius: 40px;
    border: none;
    font-size: 18px;
    padding: 10px 10px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 20px 0;
    cursor: pointer;
    transition: all 0.5s;
}

.position-selector {
    margin: 20px 0;
}

.position-options {
    display: flex;
    gap: 20px;
    justify-content: space-between; /* Ensures even spacing between the two boxes */
}

.position-box {
    flex: 1; /* Ensures each box takes equal width */
    padding: 10px;
    margin-right: 10px; /* Adds space between the boxes */
    border: 2px solid #ccc;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, border-color 0.3s;
    border-radius: 10px;
}

.position-box:last-child {
    margin-right: 0; /* Removes right margin from the last box */
}

.position-box.selected {
    background-color: #4caf50; /* Color change for selected box */
    color: white;
    border-color: #4caf50;
}

/* Hover Effect */
.position-box:hover {
    background-color: #f0f0f0; /* Light background on hover */
    transform: scale(1.05); /* Slight scaling effect */
    border-color: #999; /* Darker border on hover */
    color: black;
}



#btn:hover {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
}


.choose-position{
    color: white;
    text-align: left;
    margin-top: 30px;
}

/* Media queries for mobile view */
@media (max-width: 768px) {
    .signUp-card {
        width: 90%;
        padding: 20px;
        margin-top: 100px;
    }

    h2 {
        font-size: 1.5em;
    }

    .form-group input {
        height: 45px;
        font-size: 1em;
    }

    .form-group label {
        font-size: 1em;
    }

    .form-group .password-toggle {
        font-size: 1.1em;
    }

    #btn {
        width: 100%;
        max-width: none;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .signUp-card {
        width: 95%;
    }

    h2 {
        font-size: 1.2em;
    }

    .form-group input {
        height: 40px;
        font-size: 0.9em;
    }

    .form-group label {
        font-size: 0.9em;
    }

    .form-group .password-toggle {
        font-size: 1em;
    }

    #btn {
        font-size: 14px;
    }
}











/* Popup styles */
/* Popup styles */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    text-align: center;
    font-size: 16px;
}

/* When the popup is visible */
.popup.show {
    opacity: 1;
}

/* Responsive popup styling */
@media (max-width: 768px) {
    .popup {
        width: 90%;
        padding: 15px;
        font-size: 14px;
    }
}


.home-page-container{
    width: 30%;
    margin: auto;
    background-color: rgb(218, 232, 239);
    /* border: 2px solid red; */
}

.profile{
    /* border: 2px solid black; */
    padding: 10px 10px;
    text-align: left;
}

.main_logo{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.main_logo:hover{
    cursor: pointer;
}

.signup-container{
    /* border: 2px solid blue; */
    display: flex;
    /* height: 20px; */
    padding: 10px 10px;
    background-color: orange;
}

.signup-container > div:first-child{
    font-weight: 600;
    margin-left: 15px;
}

.signUp-btn-home{
    margin-left: 58%;
    padding: 5px 10px;
    border-radius: 20px ;
    background-color: blue;
    color: white;
    border: transparent;
}

.signUp-btn-home + button{
    margin-left: 5%;
    font-weight: 700;
    font-size: 15px;
    background-color: transparent;
    border: transparent;
}


.notification-home{
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    width: 85%;
    margin: auto;
    margin-top: 15px;
}

.notification-home > div{
    display: flex;
    gap: 15px;
    text-align: center;
    align-items: center;
    
}


.notification-home > img{
    width: 20px;
}


.notification-home > div > img{
    width: 20px;
}

.home-link{
    display: grid;
    width: 80%;
    /* border: 1px solid red; */
    grid-template-columns: repeat(3,100px);
    justify-content: center;
    background-color: white;
    margin: auto;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px 30px;
    gap: 20px;
    box-shadow: rgb(118, 149, 164) 0px 7px 29px 0px;
}

.home-link > div{
    
    padding: 2px 10px;
    border-radius: 10px;
    box-shadow: rgb(118, 149, 164) 0px 5px 15px;
}

.home-link > div > img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-top: 10px;
}

.home-link > div > p{
    margin-top: 5px;
    font-size: 12px;
    font-weight: 600;
}

.bot-status > h3{
    text-align: left;
    margin-left: 3%;
    margin-top: 30px;
}

.bot-status-home{
    display: flex;
    width: 85%;
    margin: auto;
    padding: 10px 20px;
    /* border: 1px solid red; */
    background-color: white;
    border-radius: 10px;
    border: 2px dotted orange;
    justify-content: space-between;
}


.bot-status-home > div {
    text-align: left;
}


.bot-status-home > div > button{
    padding: 5px 20px;
    border-radius: 10px;
    border: transparent;
    background-color: blue;
    color: white;
}


.bot-status-home > div > button:hover{
    cursor: pointer;
}


.bot-paused-home{
    display: flex;
    border: 2px solid rgb(255, 208, 0);
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    border-radius: 10px;
    width: 75%;
    padding: 5px 10px;
    height: 20px;
    margin-top: 20px;
}


.bot-paused-home > p{
    font-size: 14px;
    font-weight: 600;
}


.bot-paused-home > button{
    height: 20px;
    border: transparent;
    display: flex;
    border-radius: 20px;
    align-items: center;
    color: rgb(238, 216, 16);
    background-color: transparent;
    padding: 10px 5px;
    box-shadow: rgba(26, 27, 22, 0.24) 0px 3px 8px;
}


.bot-paused-home > button:hover{
    cursor: pointer;
}

.bot-paused-home > button > img{
    width: 20px;
}

.bot-status-home > img{
    width: 100px;
    height: 100px;
    margin-top: 20px;
}


.sub-home{
    display: flex;
    align-items: center;
    gap: 10px;
}


.sub-home > img{
    width: 20px;
    height: 20px;
}


.connect-home{
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    border-radius: 10px;
    justify-content: space-around;
    background-color: black;
    align-items: center;
    padding: 20px 10px;
}


.connect-home > img{
    width: 100px;
    padding: 10px 10px;
}


.connect-home > div{
    color: white;
    width: 40%;
    text-align: left;
}


.connect-home > div > p{
    font-weight: 700;
    font-size: 18px;
}


.connect-home > div > button{
    padding: 10px 20px;
    border-radius: 10px;
    border: transparent;
    background-color: blue;
    color: white;
}


.home-page-container > h4{
    font-size: 30px;
    color: gray;
    width: 40%;
    margin-left: 20px;
    text-align: left;
}


.home-page-container > p{
    color: gray;
    text-align: left;
    margin-left: 5%;
    margin-top: -20px;
}
.card-container {
    perspective: 1000px;
    width: 100%;
    height: 320px;
  }
  
  .card {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.8s;
  }
  
  .card.flipped {
    transform: rotateY(180deg);
  }
  
  .card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: linear-gradient(to right, #4b5563, #374151);
    border-radius: 10px;
    padding: 20px;
  }
  
  .card-back {
    transform: rotateY(180deg);
  }
  